import { useCallback, useEffect, useRef, useState } from 'react'
import {
	DDIDisplay,
	PaginationFilter,
} from '../../../../../utils/interfaces/ComponentModels'
import {
	AddressMap,
	CivicAddressTenantMap,
	DDI,
	MSTeamsUser,
} from '../../../../../utils/interfaces/DBModels'
import { format } from 'date-fns'
import {
	GridColDef,
	GridFilterModel,
	GridFooter,
	GridFooterContainer,
	GridRenderCellParams,
	GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../../../../styles/styledComponents/displays/StyledDataGrid'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import DDIAddressUpdate from './DDIAddressUpdate'
import {
	Backdrop,
	Box,
	IconButton,
	Modal,
	Tooltip,
	Typography,
} from '@mui/material'
import StyledModal from '../../../../../styles/styledComponents/displays/StyledModal'
import { showErrorToast } from '../../../../../utils/helperFunctions/helperFunctions'
import UseCrud from '../../../../../utils/customHooks/APICalls/UseCrud'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import {
	AddressTypes,
	DDIStatuses,
	ReturnTypes,
	Roles,
} from '../../../../../utils/enums/enums'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined'
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined'
import { StyledLoadingButton } from '../../../../../styles/styledComponents/inputs/StyledLoadingButton'
import { DataResponse } from '../../../../../utils/interfaces/APIModels'

const TNDisplay = ({
	ddiDisplay,
	addressMapList,
	getInitialDDIData,
	handleFilterData,
	maxPageNo,
	totalRecords,
	filterLoading,
	tnFilters,
}: {
	ddiDisplay: DDIDisplay[]
	addressMapList: AddressMap[]
	getInitialDDIData: () => Promise<void>
	handleFilterData: (
		pageNumber: number,
		filters: Record<string, string>
	) => Promise<void>
	maxPageNo: number
	totalRecords: number
	filterLoading: boolean
	tnFilters: PaginationFilter[]
}) => {
	// Display constants
	const [tnRows, setTNRows] = useState([] as DDIDisplay[])
	const [allColumnsSet, setAllColumnsSet] = useState(false)
	const filterAddressMapList = useRef([] as AddressMap[])
	const [selectedDDIDisplay, setSelectedDDIDisplay] = useState({} as DDIDisplay)
	const [openModal, setOpenModal] = useState(false)

	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)

	const [currentPageNo, setCurrentPageNo] = useState(1)

	// Hooks
	const { modifyData, fetchData } = UseCrud()
	// Set auto height to detail panel
	const getDetailPanelHeight = useCallback(() => 'auto', [])

	// Filters - Quick filters for the datagrid
	const [filterModel, setFilterModel] = useState<GridFilterModel>({
		items: [],
	})

	// Handle functions
	const handleUpdateTNAddressClick = async (ddiID: number, rowObj: any) => {
		if (ddiID && rowObj) {
			var _selectedDDIDisplay = ddiDisplay.find((x) => x.ID === ddiID)
			var filteredAddresses
			var civicAddressTenantMapList: CivicAddressTenantMap[]
			var tenantIDsFromMSTeamsUser: MSTeamsUser[]

			//make api call
			var data = (await fetchData({
				FileAndFunctionName: 'TNDisplay.tsx handleUpdateTNAddressClick(,) ',
				QueryURL: `GetV2?Params=CivicAddressTenantMap.All(), MSTeamsUser.Where(MSTeamsUser.CustomerID='${_selectedDDIDisplay?.CustomerID}')`,
				ErrorMessage:
					'An error occurred when fetching data from CivicAddressTenantMap and MSTeamsUser tables',
				ShowErrorToast: false,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse

			if (_selectedDDIDisplay) {
				setSelectedDDIDisplay(_selectedDDIDisplay)
			}

			if (data) {
				civicAddressTenantMapList = data.Obj.CivicAddressTenantMapList
				tenantIDsFromMSTeamsUser = data.Obj.MSTeamsUserList

				if (
					(_selectedDDIDisplay?.EgressServiceID + '').includes('TOC') &&
					tenantIDsFromMSTeamsUser &&
					tenantIDsFromMSTeamsUser.length > 0
				) {
					var getTenantIDForEgressService = tenantIDsFromMSTeamsUser.find(
						(x) =>
							x.CustomerID === _selectedDDIDisplay?.CustomerID &&
							x.ServiceID === _selectedDDIDisplay?.EgressServiceID
					)?.TenantID

					if (getTenantIDForEgressService !== null) {
						//use that tenant id to find all the addresses
						if (civicAddressTenantMapList.length > 0) {
							//filter through the list of civictenantmap and find all the civicaddress ids for that tenant id
							var listofCivicAddressIDs = civicAddressTenantMapList.filter(
								(x) => x.TenantID === getTenantIDForEgressService
							)

							//filter the addressmaplist where those civicaddressids are found
							const matchedAddresses = addressMapList.filter(
								(addressMap) =>
									listofCivicAddressIDs.some(
										(tenantMap) =>
											tenantMap.CivicAddressID ===
											addressMap.Address?.CivicAddressId
									) &&
									_selectedDDIDisplay?.CustomerID === addressMap.CustomerID &&
									addressMap?.Address?.AddressTypeID ===
										AddressTypes.Microsoft &&
									addressMap?.Address?.State?.CountryID ===
										_selectedDDIDisplay?.CountryID
							)

							filterAddressMapList.current = matchedAddresses
						}
					} else {
						filterAddressMapList.current = []
					}
				} else {
					filteredAddresses = addressMapList.filter(
						(x) =>
							x?.Address?.State?.CountryID === _selectedDDIDisplay?.CountryID &&
							_selectedDDIDisplay?.CustomerID === x.CustomerID
					)

					filterAddressMapList.current = filteredAddresses
				}
			}

			setOpenModal(true)
			handleOpenModal()
		}
	}

	const handleOpenModal = () => {
		setOpenModal(true)
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	const handleAddressUpdate = async (addressMapID: number, ddiID?: number) => {
		if (addressMapID && ddiID) {
			var matchingAddressMap = addressMapList.find(
				(x) => x.AddressMapID === Number(addressMapID)
			)
			var _ddiObj: DDI = {
				ID: ddiID,
				AddressID: matchingAddressMap?.AddressID,
				DDIStatusID:
					Number(selectedDDIDisplay.DDIStatusID) ===
					Number(DDIStatuses.PendingEmergencyAddressData)
						? Number(DDIStatuses.ReadyForImplementation)
						: Number(selectedDDIDisplay.DDIStatusID),
			}

			try {
				// Post to DB
				var postSuccess = await modifyData({
					UserName: loggedInUser.email,
					FileAndFunctionName: `TNDisplay.tsx: Update TN Location`,
					QueryURL: 'UpdateV2?Params=DDI',
					QueryObj: {
						DDI: _ddiObj,
					},
					ShowSuccessMessage: true,
					SuccessMessage: `Successfully updated TN location`,
					ShowErrorMessage: false,
					ErrorMessage: `Failed to update TN location`,
					LogErrorToDB: true,
				})

				if (postSuccess) {
					await getInitialDDIData()
					handleCloseModal()
				}
			} catch (error) {
				showErrorToast(`An error occurred when trying to update TN location`)
			}
		}
	}

	// Column Definition: Order Table
	const initialTNColumns: GridColDef[] = [
		{
			field: 'DDI',
			headerName: 'TN',
			hideable: false,
			flex: 1,
		},
		{
			field: 'AddressFriendlyName',
			headerName: 'TN Location',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'IngressServiceID',
			headerName: 'Ingress Service',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'EgressServiceID',
			headerName: 'Egress Service',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'DDIStatus',
			headerName: 'TN Status',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'FOCDate',
			headerName: 'FOC Date',
			width: 225,
			hideable: false,
			flex: 1,
			renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
				<>{params.value ? format(params.value, 'MMMM dd, yyyy') : ''}</>
			),
		},
	]
	const [tnColumns, setTNColumns] = useState<GridColDef[]>(initialTNColumns)

	useEffect(() => {
		// Set rows
		if (ddiDisplay.length > 0) {
			setTNRows(ddiDisplay)

			if (roleID === Roles.PartnerAdmin) {
				initialTNColumns.push({
					field: 'CustomerName',
					headerName: 'Customer',
					width: 220,
					hideable: false,
					flex: 1,
				})
			}

			// Add actions column for all roles
			if (roleID === Roles.CustomerAdmin) {
				initialTNColumns.push({
					field: 'ID',
					type: 'actions',
					cellClassName: 'actions',
					headerName: 'Actions',
					hideable: false,
					flex: 1,
					filterable: false,
					sortable: false,
					renderCell: (params) => (
						<Tooltip title='Update TN Address'>
							<span>
								<IconButton
									id='action-button'
									onClick={() => {
										handleUpdateTNAddressClick(Number(params.row.ID), params)
									}}>
									<SettingsOutlinedIcon />
								</IconButton>
							</span>
						</Tooltip>
					),
				})
			}

			setTNColumns(initialTNColumns)
			setAllColumnsSet(true)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ddiDisplay])

	// Handle page navigation
	const handlePrevPageClick = async (navType?: string) => {
		var _filterRecords = {} as Record<string, string>
		tnFilters.forEach((tnFilter) => {
			_filterRecords[tnFilter.Key] = tnFilter.Value
		})
		if (navType === 'first-page') {
			setCurrentPageNo(1)
			await handleFilterData(1, _filterRecords)
			return
		}

		if (currentPageNo - 1 >= 0) {
			setCurrentPageNo(currentPageNo - 1)
			await handleFilterData(currentPageNo - 1, _filterRecords)
		}
	}

	const handleNextPageClick = async (navType?: string) => {
		var _filterRecords = {} as Record<string, string>
		tnFilters.forEach((tnFilter) => {
			_filterRecords[tnFilter.Key] = tnFilter.Value
		})
		if (navType === 'last-page') {
			setCurrentPageNo(maxPageNo)
			await handleFilterData(maxPageNo, _filterRecords)
			return
		}

		if (currentPageNo + 1 <= maxPageNo) {
			setCurrentPageNo(currentPageNo + 1)
			await handleFilterData(currentPageNo + 1, _filterRecords)
		}
	}

	//1 - Custom Footer
	function CustomFooter() {
		return !filterLoading ? (
			<GridFooterContainer className='order-footer'>
				<GridFooter sx={{ borderTop: 'none' }} />
				<Box className='order-footer-content'>
					{/* Total rows */}
					<Box className='page-info'>
						<Typography>
							Page <strong>{currentPageNo}</strong> - {maxPageNo} of{' '}
							{totalRecords} Results
						</Typography>
					</Box>
					{/* Pagination */}
					<Box className='order-pagination'>
						{/* Skip to first page */}
						<Tooltip title='Skip to first page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === 1}
									onClick={() => handlePrevPageClick('first-page')}>
									<SkipPreviousOutlinedIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>

						{/* Previous */}
						<Tooltip title='Previous page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === 1}
									onClick={() => handlePrevPageClick()}>
									<NavigateBeforeIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
						{/* Next */}
						<Tooltip title='Next Page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === maxPageNo}
									onClick={() => handleNextPageClick()}>
									<NavigateNextIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
						{/* Skip to last page */}
						<Tooltip title='Skip to last page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === maxPageNo}
									onClick={() => handleNextPageClick('last-page')}>
									<SkipNextOutlinedIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
					</Box>
				</Box>
			</GridFooterContainer>
		) : (
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={filterLoading}
			/>
		)
	}

	return (
		<>
			{/* Datagrid: TNs */}
			{allColumnsSet && (
				<StyledDataGrid
					rows={tnRows}
					columns={tnColumns}
					editMode='row'
					checkboxSelection={false}
					pageSizeOptions={[5]}
					slots={{
						footer: CustomFooter,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.ID}
					autoHeight={true}
					rowSelection={false}
					getDetailPanelHeight={getDetailPanelHeight}
					filterModel={filterModel}
					onFilterModelChange={(newFilterModel) =>
						setFilterModel(newFilterModel)
					}
					hideFooterRowCount
					disableColumnMenu
				/>
			)}

			{/* Modal to update TN location */}
			<Modal open={openModal}>
				<StyledModal width={700}>
					<DDIAddressUpdate
						isRange={false}
						ddiDisplay={selectedDDIDisplay}
						addressMapList={filterAddressMapList.current}
						handleAddressUpdate={handleAddressUpdate}
						onClose={handleCloseModal}
					/>
				</StyledModal>
			</Modal>
		</>
	)
}

export default TNDisplay
