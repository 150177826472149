import {
	Box,
	Divider,
	MenuItem,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { StyledTextField } from '../../../../../../styles/styledComponents/inputs/StyledTextField'
import { useEffect, useState } from 'react'
import { DataResponse } from '../../../../../../utils/interfaces/APIModels'
import { StyledSelect } from '../../../../../../styles/styledComponents/inputs/StyledSelect'
import { AddressTypes, ReturnTypes } from '../../../../../../utils/enums/enums'
import { StyledLoadingButton } from '../../../../../../styles/styledComponents/inputs/StyledLoadingButton'
import { AddressType } from '../../../../../../utils/interfaces/ComponentModels'
import {
	Address,
	AddressMap,
	Country,
	State,
} from '../../../../../../utils/interfaces/DBModels'
import './AddAddressForm.scss'
import {
	hexToRgba,
	objectToStringForGet,
	validateField,
} from '../../../../../../utils/helperFunctions/helperFunctions'
import UseCrud from '../../../../../../utils/customHooks/APICalls/UseCrud'
import { RootState } from '../../../../../../store/store'
import { useSelector } from 'react-redux'

const AddUpdateAddressForm = ({
	stateList,
	countryList,
	editAddress,
	selectedRow,
	getAddresses,
	clearSelectedRow,
}: {
	stateList: State[]
	countryList: Country[]
	editAddress: boolean
	selectedRow: any
	getAddresses: any
	clearSelectedRow: any
}) => {
	const [addressName, setAddressName] = useState('')
	const [houseNumber, setHouseNumber] = useState('')
	const [addressLine1, setAddressLine1] = useState('')
	const [addressLine2, setAddressLine2] = useState('')
	const [city, setCity] = useState('')
	const [postalCode, setPostalCode] = useState('')

	const [selectedAddressTypeID, setSelectedAddressTypeID] = useState(0)
	const [selectedCountryID, setSelectedCountryID] = useState(0)
	const [selectedStateID, setSelectedStateID] = useState(0)

	const [loadingButton, setLoadingButton] = useState(false)

	const [isMicrosoftAddress, setIsMicrosoftAddress] = useState(false)

	//lists
	const [addressTypeList, setAddressTypeList] = useState<AddressType[]>([])
	const [stateListDisplay, setStateDisplayList] = useState([] as State[])
	const [countryListDisplay, setCountryDisplayList] = useState([] as Country[])

	// errorMessages
	const [addressNameErrMsg, setAddressNameErrMsg] = useState('')
	const [houseNumberErrMsg, setHouseNumberErrMsg] = useState('')
	const [addressLine1ErrMsg, setAddressLine1ErrMsg] = useState('')
	const [addressLine2ErrMsg, setAddressLine2ErrMsg] = useState('')
	const [cityErrMsg, setCityErrMsg] = useState('')
	const [postalCodeErrMsg, setPostalCodeErrMsg] = useState('')

	const theme = useTheme()

	const { fetchData, modifyData } = UseCrud()

	var loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	useEffect(() => {
		var _addressTypes: AddressType[] = []

		for (var addressType in AddressTypes) {
			if (!isNaN(Number(addressType))) {
				_addressTypes.push({
					AddressTypeID: Number(addressType),
					AddressTypeName: AddressTypes[addressType],
				})
			}
		}

		// Sort Address, Country and State Lists
		setAddressTypeList(
			_addressTypes.sort((a, b) =>
				a.AddressTypeName!.localeCompare(b.AddressTypeName!, undefined, {
					sensitivity: 'base',
				})
			)
		)

		setCountryDisplayList(
			countryList.sort((a, b) =>
				a.CountryName!.localeCompare(b.CountryName!, undefined, {
					sensitivity: 'base',
				})
			)
		)

		setStateDisplayList(
			stateList.sort((a, b) =>
				a.StateName!.localeCompare(b.StateName!, undefined, {
					sensitivity: 'base',
				})
			)
		)

		if (selectedRow === null) {
			// Clear Form & initialize (addressType, Country and State)
			clearForm(_addressTypes)
		} else {
			// Initialize form with selected Customer Address
			setAddressName(selectedRow?.AddressName ? selectedRow?.AddressName : '')
			setHouseNumber(selectedRow?.HouseNumber)
			setAddressLine1(selectedRow?.AddressLine1)
			setAddressLine2(selectedRow?.AddressLine2)

			var selectedAddress = _addressTypes.find(
				(address: AddressType) =>
					address.AddressTypeName === selectedRow?.AddressType
			)

			if (selectedAddress?.AddressTypeID === AddressTypes.Microsoft) {
				setIsMicrosoftAddress(true)
			} else {
				setIsMicrosoftAddress(false)
			}

			setSelectedAddressTypeID(Number(selectedAddress?.AddressTypeID))
			setCity(selectedRow?.City)

			const selectedCountry = countryList.find(
				(country: Country) => country.CountryName === selectedRow.Country
			)

			setSelectedCountryID(Number(selectedCountry?.CountryID))

			const selectedState = stateList.find(
				(state: State) => state.StateName === selectedRow.State
			)

			setSelectedStateID(Number(selectedState?.StateID))

			setPostalCode(selectedRow?.PostalCode)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryList, stateList, selectedRow])

	const handleAddressName = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressName(newValue)
		setAddressNameErrMsg(validateField('addressName', newValue + ''))
	}

	const handleHouseNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setHouseNumber(newValue)

		setHouseNumberErrMsg(validateField('houseNumber', newValue + ''))
	}

	const handleAddressLine1 = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressLine1(newValue)
		setAddressLine1ErrMsg(validateField('addressLine1', newValue + ''))
	}

	const handleAddressLine2 = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressLine2(newValue)
		setAddressLine2ErrMsg(validateField('addressLine2', newValue + ''))
	}

	const handleAddressType = (e: { target: any }) => {
		var newValue = e.target.value
		setSelectedAddressTypeID(Number(newValue))
	}

	const handleCity = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setCity(newValue)
		setCityErrMsg(validateField('city', newValue + ''))
	}

	const handleState = (e: { target: any }) => {
		var newValue = e.target.value
		setSelectedStateID(newValue)
	}

	const handleCountry = (e: { target: any }) => {
		var newValue = e.target.value
		setSelectedCountryID(Number(newValue))

		var filterStateList = stateList
			.filter((x) => x.CountryID === newValue)
			.sort((a, b) =>
				a.StateName!.localeCompare(b.StateName!, undefined, {
					sensitivity: 'base',
				})
			)

		setStateDisplayList(filterStateList)
		setSelectedStateID(Number(filterStateList[0]?.StateID))
	}

	const handlePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setPostalCode(newValue)
		setPostalCodeErrMsg(validateField('postalCode', newValue + ''))
	}

	const disableAddAddressBtn = () => {
		if (
			addressName === null || // Checks if addressName is null
			addressName.trim() === '' || //Checks if addressName is undefined, or empty
			selectedAddressTypeID === 0 ||
			selectedStateID === 0 ||
			selectedCountryID === 0 ||
			!addressLine1 || // Checks if addressLine1 is null, undefined, or empty
			addressNameErrMsg?.length > 0 || // Safely checks the length of error messages
			houseNumberErrMsg?.length > 0 ||
			addressLine1ErrMsg?.length > 0 ||
			addressLine2ErrMsg?.length > 0 ||
			cityErrMsg?.length > 0 ||
			postalCodeErrMsg?.length > 0
		) {
			return true
		} else {
			return false
		}
	}

	const clearForm = (_addressTypeList: AddressType[]) => {
		setAddressName('')
		setHouseNumber('')
		setAddressLine1('')
		setAddressLine2('')
		setCity('')
		setPostalCode('')
		// Initialize dropdowns to 1st items in respective lists
		setSelectedAddressTypeID(Number(_addressTypeList[0].AddressTypeID))
		setSelectedCountryID(Number(countryList[0].CountryID))
		setSelectedStateID(Number(stateList[0].StateID))
		// Reset error messages
		setAddressNameErrMsg('')
		setHouseNumberErrMsg('')
		setAddressLine1ErrMsg('')
		setAddressLine2ErrMsg('')
		setCityErrMsg('')
		setPostalCodeErrMsg('')
	}

	const addAddress = async (e: { target: any }) => {
		//show loading indicator
		setLoadingButton(true)

		//create empty variables
		var addressObj = {} as Address
		var dataResponse
		var addressRequestObj = {} as any
		var addressRequestURL = ''
		if (editAddress === true) {
			const selectedAddressType = AddressTypes[selectedAddressTypeID]
			const selectedCountry = countryList.find(
				(country: Country) => country.CountryID === selectedCountryID
			)?.CountryName
			const selectedState = stateList.find(
				(state: State) => state.StateID === selectedStateID
			)?.StateName
			//check if something has changed
			if (
				addressName !== selectedRow.AddressName ||
				selectedAddressType !== selectedRow.AddressTypeID ||
				houseNumber !== selectedRow.HouseNumber ||
				addressLine1 !== selectedRow.AddressLine1 ||
				addressLine2 !== selectedRow.AddressLine2 ||
				city !== selectedRow.City ||
				selectedCountry !== selectedRow.Country ||
				selectedState !== selectedRow.State ||
				postalCode !== selectedRow.PostalCode
			) {
				//update existing address
				addressObj = {
					AddressID: selectedRow.AddressID,
					AddressTypeID: selectedAddressTypeID,
					HouseNumber: houseNumber?.trim(),
					AddressLine1: addressLine1.trim(),
					AddressLine2: addressLine2?.trim(),
					City: city,
					StateID: selectedStateID,
					PostalCode: postalCode?.trim(),
				}

				addressRequestObj = {
					Address: addressObj,
				}

				dataResponse = (await modifyData({
					FileAndFunctionName: 'AddAddressForm.tsx addAddress',
					QueryURL: `UpdateV2?Params=Address`,
					QueryObj: addressRequestObj,
					ErrorMessage: 'Could not update address',
					ShowErrorMessage: true,
					ShowSuccessMessage: false,
					SuccessMessage: 'Address Successfully Updated',
					LogErrorToDB: true,
					UserName: loggedInUser.email,
				})) as Boolean

				if (dataResponse) {
					if (selectedRow.addressName !== addressName) {
						addressRequestURL = objectToStringForGet(
							'AddressMap',
							addressRequestObj.Address
						)

						if (addressRequestURL) {
							if (addressRequestURL) {
								await addAddressMap(addressRequestURL)
							}
						}
					}
				}
			}
		} else {
			//add new address
			addressObj = {
				AddressTypeID: selectedAddressTypeID,
				HouseNumber: houseNumber?.trim(),
				AddressLine1: addressLine1.trim(),
				AddressLine2: addressLine2?.trim(),
				City: city,
				StateID: selectedStateID,
				PostalCode: postalCode?.trim(),
			}

			addressRequestObj = {
				Address: addressObj,
			}

			dataResponse = (await modifyData({
				FileAndFunctionName: 'AddAddressForm.tsx addAddress',
				QueryURL: `AddV2?Params=Address`,
				QueryObj: addressRequestObj,
				ErrorMessage: 'Could not add address',
				ShowErrorMessage: true,
				ShowSuccessMessage: false,
				SuccessMessage: 'Address Successfully added',
				LogErrorToDB: true,
				UserName: loggedInUser.email,
			})) as Boolean

			if (dataResponse) {
				addressRequestURL = objectToStringForGet(
					'Address',
					addressRequestObj.Address
				)
				if (addressRequestURL) {
					if (addressRequestURL) {
						await addAddressMap(addressRequestURL)
					}
				}
			}
		}
		setLoadingButton(false)
	}

	const addAddressMap = async (addressRequestURL: string) => {
		var addressMapObj = {} as AddressMap
		var addressMapRequest = {} as any
		var postSuccess

		if (editAddress === true) {
			//update address map
			addressMapObj = {
				AddressMapID: selectedRow.AddressMapID,
				AddressID: selectedRow.AddressID,
				CarrierID: 1,
				CustomerID: loggedInUser.customerID,
				AddressName: addressName, //friendly name
			}

			addressMapRequest = {
				AddressMap: addressMapObj,
			}

			//post to DB
			postSuccess = await modifyData({
				FileAndFunctionName: 'AddAddressForm.tsx addAddressMap',
				QueryURL: 'UpdateV2?Params=AddressMap',
				QueryObj: addressMapRequest,
				ErrorMessage: 'Failed to update address',
				ShowErrorMessage: false,
				ShowSuccessMessage: true,
				SuccessMessage: 'Updated Address successfully',
				LogErrorToDB: true,
				UserName: loggedInUser.email,
			})

			if (postSuccess) {
				getAddresses()
				clearForm(addressTypeList)
				clearSelectedRow()
			}
		} else {
			//add address map
			var requestURL = `GetV2?Params=Address.Where(${addressRequestURL}).Pagination(NumberOfRows = '1' & PageNumber = '1' & Column = 'Address.AddressID' & SortType = 'DESC')`

			var dataResponse = (await fetchData({
				FileAndFunctionName: 'AddAddressForm.tsx: addAddressMap',
				QueryURL: requestURL,
				ErrorMessage: 'An error occurred while fetching the address map record',
				ShowErrorToast: true,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse

			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				var addressID1 = dataResponse.Obj.AddressList[0].AddressID

				addressMapObj = {
					AddressID: addressID1,
					CarrierID: 1,
					CustomerID: loggedInUser.customerID,
					AddressName: addressName, //friendly name
				}

				addressMapRequest = {
					AddressMap: addressMapObj,
				}

				//post to DB
				postSuccess = await modifyData({
					FileAndFunctionName: 'AddAddressForm.tsx addAddressMap',
					QueryURL: `AddV2?Params=AddressMap`,
					QueryObj: addressMapRequest,
					ErrorMessage: 'Failed to add address',
					ShowErrorMessage: true,
					ShowSuccessMessage: true,
					SuccessMessage: 'Added Address successfully',
					LogErrorToDB: true,
					UserName: loggedInUser.email,
				})

				if (postSuccess) {
					getAddresses()
					clearForm(addressTypeList)
				}
			}
		}
	}

	return (
		<>
			<Box>
				{/* form heading  */}
				<Box className='header'>
					<Typography component='h4'>Customer Address</Typography>
				</Box>

				{/* add/update address form */}
				<Box
					sx={{
						backgroundColor: hexToRgba(theme.palette.secondary.main, 0.3),
						border: `2px solid${theme.palette.secondary.main}`,
						borderRadius: '10px',
					}}>
					<Box className='add-address-box'>
						<Typography component='p'>
							Address Name:
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<Box>
							<StyledTextField
								fullWidth
								type='text'
								onChange={handleAddressName}
								error={addressNameErrMsg.length > 0}
								helperText={addressNameErrMsg}
								value={addressName}
								style={{ backgroundColor: 'white' }}
							/>
						</Box>

						<Typography component='p'>
							Address Type:
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<Box>
							<StyledSelect
								fullWidth
								disabled={isMicrosoftAddress}
								onChange={handleAddressType}
								type='text'
								value={selectedAddressTypeID}
								style={{ backgroundColor: 'white' }}>
								{addressTypeList.map((item: AddressType, index: any) => {
									return (
										<MenuItem key={index} value={item.AddressTypeID}>
											{item.AddressTypeName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>

						<Typography component='p'>House number:</Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleHouseNumber}
								error={houseNumberErrMsg.length > 0}
								helperText={houseNumberErrMsg}
								value={houseNumber}
								style={{ backgroundColor: 'white' }}
							/>
						</Box>

						<Typography component='p'>City: </Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleCity}
								value={city}
								error={cityErrMsg.length > 0}
								helperText={cityErrMsg}
								style={{ backgroundColor: 'white' }}
							/>
						</Box>

						<Typography component='p'>
							Address Line 1:
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleAddressLine1}
								error={addressLine1ErrMsg.length > 0}
								helperText={addressLine1ErrMsg}
								value={addressLine1}
								style={{ backgroundColor: 'white' }}
							/>
						</Box>

						<Typography component='p'>
							Country:
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<Box>
							<StyledSelect
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleCountry}
								value={selectedCountryID}
								style={{ backgroundColor: 'white' }}>
								{countryListDisplay.map((item: Country, index: any) => {
									return (
										<MenuItem key={index} value={item.CountryID}>
											{item.CountryName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>

						<Typography component='p'>Address Line 2:</Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleAddressLine2}
								error={addressLine2ErrMsg.length > 0}
								helperText={addressLine2ErrMsg}
								value={addressLine2}
								style={{ backgroundColor: 'white' }}
							/>
						</Box>

						<Typography component='p'>
							State:
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<Box>
							<StyledSelect
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleState}
								value={selectedStateID}
								style={{ backgroundColor: 'white' }}>
								{stateListDisplay.map((item: State, index: any) => {
									return (
										<MenuItem key={index} value={item.StateID}>
											{item.StateName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>

						<Box></Box>
						<Box></Box>

						<Typography component='p'>Postal Code: </Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handlePostalCode}
								value={postalCode}
								error={postalCodeErrMsg.length > 0}
								helperText={postalCodeErrMsg}
								style={{ backgroundColor: 'white' }}
							/>
						</Box>
					</Box>

					<Divider />

					<br />

					<Tooltip
						title={
							disableAddAddressBtn() &&
							'Please ensure all the required fields are filled in'
						}>
						<span>
							<StyledLoadingButton
								className='address-btn'
								loading={loadingButton}
								onClick={addAddress}
								disabled={disableAddAddressBtn()}>
								{!editAddress ? 'Add' : 'Edit'} Address
							</StyledLoadingButton>
						</span>
					</Tooltip>

					<br />
				</Box>
			</Box>
		</>
	)
}

export default AddUpdateAddressForm
