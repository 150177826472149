import {
	Badge,
	Box,
	Button,
	Divider,
	Popover,
	Tab,
	Tabs,
	Typography,
} from '@mui/material'
import {
	DDIDisplay,
	DDIRangeDisplay,
	PaginationFilter,
} from '../../../../../utils/interfaces/ComponentModels'
import { useEffect, useState } from 'react'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { AddressMap } from '../../../../../utils/interfaces/DBModels'
import { TabPanel } from './TabPanel'
import TNDisplay from './TNDisplay'
import TNRangeDisplay from './TNRangeDisplay'
import TnFilters from './TnFilters/TnFilters'
import { Roles, TNFilterKeyMappings } from '../../../../../utils/enums/enums'
import { FilterRequest } from '../../../../../utils/interfaces/APIModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'

const TNOverviewDisplay = ({
	ddiDisplay,
	ddiRangeDisplay,
	addressMapList,
	handleFilterData,
	getFilteredDDIsData,
	maxPageNo,
	totalRecords,
	filterLoading,
	getInitialDDIRangeData,
	ddiRangesMaxPageNo,
	ddiRangesTotalRecords,
	getInitialDDIData,
	tnFilters,
	onUpdateTNFilters,
}: {
	ddiDisplay: DDIDisplay[]
	ddiRangeDisplay: DDIRangeDisplay[]
	addressMapList: AddressMap[]
	handleFilterData: (
		pageNumber: number,
		filters: Record<string, string>
	) => Promise<void>
	getFilteredDDIsData: (filterRequest: FilterRequest) => Promise<void>
	maxPageNo: number
	totalRecords: number
	filterLoading: boolean
	getInitialDDIRangeData: (
		pageNumber: number,
		filters: Record<string, string>
	) => Promise<void>
	ddiRangesMaxPageNo: number
	ddiRangesTotalRecords: number
	getInitialDDIData: () => Promise<void>
	tnFilters: PaginationFilter[]
	onUpdateTNFilters: any
}) => {
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Display constants
	const [filterAnchorEl, setFilterAnchorEl] =
		useState<HTMLButtonElement | null>(null)
	const openFilter = Boolean(filterAnchorEl)
	const filterID = openFilter ? 'simple-popover' : undefined

	//useState
	const [tabValue, setTabValue] = useState(0)

	useEffect(() => {
		if (tabValue === 0) {
			getInitialDDIData()
		} else {
			getInitialDDIRangeData(1, {} as Record<string, string>)
		}
	}, [tabValue])

	//handle tab value change
	const handleTabChange = (event: React.SyntheticEvent, newvalue: number) => {
		setTabValue(newvalue)
	}

	// Build filter
	const createFiltersRecord = (
		filtersArray: PaginationFilter[]
	): Record<string, string> => {
		var _filtersArray = filtersArray.reduce((acc, filter) => {
			const keys = Object.keys(TNFilterKeyMappings) as Array<
				keyof typeof TNFilterKeyMappings
			>
			var findKey = keys.find((key) => TNFilterKeyMappings[key] === filter.Key)

			acc[findKey ?? ''] = (filter.Value + '').trim()
			return acc
		}, {} as Record<string, string>)

		if (roleID === Roles.CustomerAdmin) {
			_filtersArray['CustomerPartnerID'] = `${partnerID}`
			_filtersArray['CustomerID'] = `${loggedInUser.customerID}`
		} else {
			_filtersArray['CustomerPartnerID'] = `${partnerID}`
		}

		return _filtersArray
	}

	// Handle open filter menu
	const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
		setFilterAnchorEl(event.currentTarget)
	}

	// Handle close filter menu
	const handleCloseFilter = () => {
		setFilterAnchorEl(null)
	}

	// Handle filter call logic
	const handleFilterCall = async (
		tnFilters: PaginationFilter[],
		resetFilters: boolean
	) => {
		if (resetFilters) {
			if (tabValue === 0) {
				getInitialDDIData()
			} else {
				getInitialDDIRangeData(1, {} as Record<string, string>)
			}
			return
		} else {
			onUpdateTNFilters(tnFilters)
		}

		//Loop through filters and build filter dictionary
		var builtFilters = createFiltersRecord(tnFilters)
		if (builtFilters) {
			var filters: FilterRequest = {
				pageNo: 1,
				pageSize: 10,
				filters: builtFilters,
			}

			if (tnFilters && tnFilters.length > 0) {
				await getFilteredDDIsData(filters)
			} else {
				var _filterRecords = {} as Record<string, string>
				tnFilters.forEach((tnFilter) => {
					_filterRecords[tnFilter.Key] = tnFilter.Value
				})
				await getInitialDDIRangeData(filters.pageNo, _filterRecords)
			}
		}
	}

	return (
		<Box className='tn-overview-container'>
			{/* Order status filter */}
			<Box className='tn-overview-filters'>
				<Box className='description'>
					<Typography component='p'>
						Manage your TNs, update emergency address details.
					</Typography>
				</Box>
			</Box>

			<Box className='order-overview-filters'>
				{/* Filter button */}
				<Box>
					{/* Button */}
					<Button
						startIcon={
							<Badge badgeContent={tnFilters.length} color='primary'>
								<FilterListOutlinedIcon />
							</Badge>
						}
						variant='outlined'
						aria-describedby={filterID}
						onClick={handleOpenFilter}>
						Filter
					</Button>
					{/* Filter popover */}
					<Popover
						id={filterID}
						open={openFilter}
						anchorEl={filterAnchorEl}
						onClose={handleCloseFilter}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}>
						<TnFilters
							tnFilters={tnFilters}
							tabValue={tabValue}
							handleFilterCall={handleFilterCall}
						/>
					</Popover>
				</Box>
			</Box>

			{/* tab panel - Documents and FAQs */}
			<Box className='support-tab-container'>
				<Tabs value={tabValue} onChange={handleTabChange}>
					<Tab label='TNs' />
					<Tab label='TN Ranges' />
				</Tabs>

				{/* Documents tab */}
				<TabPanel index={0} value={tabValue}>
					<TNDisplay
						ddiDisplay={ddiDisplay}
						addressMapList={addressMapList}
						getInitialDDIData={getInitialDDIData}
						handleFilterData={handleFilterData}
						maxPageNo={maxPageNo}
						totalRecords={totalRecords}
						filterLoading={filterLoading}
						tnFilters={tnFilters}
					/>
				</TabPanel>
				<TabPanel index={1} value={tabValue}>
					<TNRangeDisplay
						ddiRangeDisplay={ddiRangeDisplay}
						ddiDisplay={ddiDisplay}
						addressMapList={addressMapList}
						getInitialDDIData={getInitialDDIData}
						getInitialDDIRangeData={getInitialDDIRangeData}
						filterLoading={filterLoading}
						ddiRangesMaxPageNo={ddiRangesMaxPageNo}
						ddiRangesTotalRecords={ddiRangesTotalRecords}
						tnFilters={tnFilters}
					/>
				</TabPanel>
			</Box>

			<Divider />
		</Box>
	)
}

export default TNOverviewDisplay
