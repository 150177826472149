import { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
	SelectPaginationFilters,
	PaginationFilter,
} from '../../../../../../utils/interfaces/ComponentModels'
import { Box, Button, Divider, MenuItem, Typography } from '@mui/material'
import { StyledSelect } from '../../../../../../styles/styledComponents/inputs/StyledSelect'
import { LoadingButton } from '@mui/lab'
import { StyledChip } from '../../../../../../styles/styledComponents/inputs/StyledChip'
import AddIcon from '@mui/icons-material/Add'
import {
	DDIStatuses,
	TNFilterKeyMappings,
} from '../../../../../../utils/enums/enums'
import { StyledTextField } from '../../../../../../styles/styledComponents/inputs/StyledTextField'
import {
	getEnumKeyByValue,
	getEnumKeysAsArray,
	getEnumValueByKey,
	isEmptyOrWhitespace,
} from '../../../../../../utils/helperFunctions/helperFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'

const TnFilters = ({
	tnFilters,
	tabValue,
	handleFilterCall,
}: {
	tnFilters: PaginationFilter[]
	tabValue: number
	handleFilterCall: any
}) => {
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	// Fields to filter by
	const TNFilters = ['TN', 'Ingress ServiceID', 'Egress ServiceID', 'TN Status']

	const TNRangesFilters = ['TN Start', 'TN End']

	// Set containing all the fields that require a SelectBox
	const requiredFilterKeys = new Set([TNFilterKeyMappings.DDIStatusID])

	// Display constants
	const [tnFilterList, setTNFilterList] = useState([] as PaginationFilter[])
	const [tnFilterKey, setTNFilterKey] = useState('Please select a filter')
	const [tnFilterValue, setTNFilterValue] = useState('')
	const [selectFilterList, setSelectFilterList] = useState(
		[] as SelectPaginationFilters[]
	)

	// Flags
	const [filterLoading, setFilterLoading] = useState(false)
	const [clearLoading, setClearLoading] = useState(false)
	const [showSelectFilter, setShowSelectFilter] = useState(false)
	const tnFilterMapping = useRef<TNFilterKeyMappings>()

	const filteresTNFilterKeys = useRef<string[]>([])

	useEffect(() => {
		if (tabValue === 0) {
			filteresTNFilterKeys.current = TNFilters

			if (partnerID) {
				filteresTNFilterKeys.current.push('Customer Name')
			}
		} else {
			filteresTNFilterKeys.current = TNRangesFilters
		}

		//Filters
		if (tnFilters) {
			setTNFilterList(tnFilters)
		}

		setSelectFilterList(createSelectTnFiltersArray(tnFilterKey))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tnFilters])

	// Handle Functions
	const handleFilterKeyChange = (e: { target: any }) => {
		//reset the value
		setTNFilterValue('')

		var keyValue = e.target.value
		setTNFilterKey(keyValue)

		const requiresSelect = handleShowSelectFilter(keyValue)
		setShowSelectFilter(requiresSelect)
		if (requiresSelect) {
			setSelectFilterList(createSelectTnFiltersArray(keyValue))
		}
	}

	// Check if filter is already applied
	const filterApplied = (filterKey: string) => {
		if (tnFilterList.find((o) => o.Key === filterKey)) {
			return true
		} else {
			return false
		}
	}

	// Handle add filter
	const addFilter = () => {
		setTNFilterList((prev) => [
			...prev,
			{
				Key: tnFilterKey,
				Value: tnFilterValue,
			},
		])
		// Clear the constants
		setTNFilterKey('')
		setTNFilterValue('')
	}

	// Handle remove filter
	const removeFilter = async (key: string) => {
		// Filter out the item with the matching key
		const filteredTnFilterList = tnFilterList.filter((item) => item.Key !== key)
		// Update the state with the new list
		setTNFilterList(filteredTnFilterList)

		// Make call ONLY if the filter has been applied
		if (tnFilters.find((o) => o.Key === key)) {
			await handleFilterCall(filteredTnFilterList)
		}
	}

	// Apply filters
	const applyFilters = async () => {
		// Loading
		setFilterLoading(true)

		// Ensure the list has values
		if (tnFilterList.length > 0) {
			await handleFilterCall(tnFilterList)
		}

		// Loading
		setFilterLoading(false)
	}

	// Clear filters
	const clearFilters = async () => {
		// Loading
		setClearLoading(true)

		// Make call
		await handleFilterCall([], true)
		setClearLoading(false)
	}

	// Check if select filter is needed
	const handleShowSelectFilter = (tnFilterKey: string): boolean => {
		const requiresSelect = requiredFilterKeys.has(
			tnFilterKey as TNFilterKeyMappings
		)
		if (requiresSelect) {
			tnFilterMapping.current = getEnumKeyByValue(
				TNFilterKeyMappings,
				tnFilterKey
			) as TNFilterKeyMappings
		}

		return requiresSelect
	}

	// Show the name of the enums to display with spaces
	const formatDisplayName = (key: string): string => {
		return key
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, (str) => str.toUpperCase())
	}

	// Create filtered array
	const createSelectTnFiltersArray = (
		_tnFilterKey: string
	): SelectPaginationFilters[] => {
		const selectedTNFilters = [] as SelectPaginationFilters[]

		if (isEmptyOrWhitespace(_tnFilterKey)) {
			return selectedTNFilters
		}

		var enumKeys: string[] = []
		const excludeKeys: Set<string> = new Set()

		enumKeys = getEnumKeysAsArray(DDIStatuses, true, excludeKeys)
		enumKeys.forEach((key: string) => {
			const formattedDisplayName = formatDisplayName(key)
			selectedTNFilters.push({
				FilterCategory: TNFilterKeyMappings.DDIStatusID,
				FilterID: getEnumValueByKey(DDIStatuses, key),
				FilterDisplayName: formattedDisplayName.trim(),
			})
		})

		return selectedTNFilters
	}

	// Handle filter value change
	const handleFilterValueChange = (
		e: ChangeEvent<{ value: unknown }>
	): void => {
		setTNFilterValue(e.target.value as string)
	}

	return (
		<>
			<Box className='order-filter-container'>
				{/* Filter box */}
				<Box className='order-filter-content'>
					{/* Filter by */}
					<Box className='order-filter'>
						{/* Label */}
						<Typography component='p'>Filter by:</Typography>
						{/* Dropdown */}
						<StyledSelect
							fullWidth
							value={tnFilterKey}
							type='text'
							onChange={handleFilterKeyChange}>
							<MenuItem disabled value='Please select a filter'>
								Please select a filter
							</MenuItem>
							{filteresTNFilterKeys.current?.map((item) => {
								return (
									!filterApplied(item) && (
										<MenuItem key={item} value={item}>
											{item}
										</MenuItem>
									)
								)
							})}
						</StyledSelect>
					</Box>
					{/* Value */}
					<Box className='order-filter'>
						{/* Label */}
						<Typography component='p'>Value:</Typography>
						{/* Textfield */}
						<StyledTextField
							fullWidth
							select={showSelectFilter}
							disabled={tnFilterKey.length === 0}
							value={tnFilterValue}
							onChange={handleFilterValueChange}>
							{showSelectFilter &&
								selectFilterList
									.filter((item) => item.FilterCategory === tnFilterKey)
									.map((item) => (
										<MenuItem key={item.FilterID} value={item.FilterID}>
											{item.FilterDisplayName}
										</MenuItem>
									))}
						</StyledTextField>
					</Box>
					{/* Add filter button */}
					<Box sx={{ width: '145px' }} className='add-filter'>
						<Button
							disabled={tnFilterValue.length === 0}
							startIcon={<AddIcon />}
							variant='outlined'
							onClick={addFilter}>
							Add filter
						</Button>
					</Box>
				</Box>
				{/* Filters applied */}
				{tnFilterList.length > 0 && (
					<Box className='filters-applied'>
						{/* Divider */}
						<Divider />
						{/* Header */}
						<Typography component='h4'>Filters applied:</Typography>
						{/* Chips */}
						<Box className='filter-chips'>
							{tnFilterList.map((item, index) => (
								<StyledChip
									variant='outlined'
									key={index}
									label={`${item.Key}: ${item.Value}`}
									onDelete={() => removeFilter(item.Key)}
								/>
							))}
						</Box>
					</Box>
				)}
				{/* Divider */}
				<Divider />
				{/* Buttons */}
				<Box className='filter-buttons'>
					{/* Clear filters */}
					<LoadingButton
						disabled={tnFilterList.length === 0}
						loading={clearLoading}
						variant='outlined'
						onClick={clearFilters}>
						Clear Filters
					</LoadingButton>
					{/* Apply */}
					<LoadingButton
						disabled={tnFilterList.length === 0}
						loading={filterLoading}
						variant='contained'
						onClick={applyFilters}>
						Apply Filters
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default TnFilters
