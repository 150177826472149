import { MenuCategories, Permissions } from '../enums/enums'
import { RouteItems } from '../interfaces/ComponentModels'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined'
import Home from '../../pages/home/Home'
import OrderManagement from '../../pages/management/OrderManagement/OrderManagement'
import CustomerManagement from '../../pages/management/CustomerManagement/CustomerManagement'
import CustomerOverview from '../../pages/management/CustomerManagement/CustomerOverview/CustomerOverview'
import LeadManagement from '../../pages/management/LeadManagement/LeadManagement'
import PriceBookManagement from '../../pages/management/PriceBookManagement/PriceBookManagement'
import KnowledgeBase from '../../pages/knowledgeBase/KnowledgeBase'
import Settings from '../../pages/settings/Settings'
import TeamsUserManagement from '../../pages/management/TeamsUserManagement/TeamsUserManagement'
import Icon from '@mdi/react'
import { mdiMapMarkerRadius, mdiTextBoxEditOutline } from '@mdi/js'
import TNManagement from '../../pages/management/TNManagement/TNMangement'
import AddressManagement from '../../pages/management/AddressManagement/AddressManagement'
import Compliance from '../../pages/compliance/Compliance'
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload'

// Routes
export const initialRouteList: RouteItems[] = [
	// Dashboard
	{
		RouteLabel: 'Dashboard',
		RouteLink: '/',
		RouteIcon: <DashboardOutlinedIcon />,
		RouteElement: <Home />,
		IsActive: false,
		MenuCategory: MenuCategories.Main,
		PermissionRequired: 0,
	},
	{
		RouteLabel: 'Order Management',
		RouteLink: '/order-management',
		RouteIcon: <Icon path={mdiTextBoxEditOutline} size={1} />,
		RouteElement: OrderManagement,
		IsActive: false,
		MenuCategory: MenuCategories.Main,
		PermissionRequired: Permissions.ViewOrders,
	},
	{
		RouteLabel: 'TN Management',
		RouteLink: '/tn-management',
		RouteIcon: <PhoneInTalkOutlinedIcon />,
		RouteElement: TNManagement,
		IsActive: false,
		MenuCategory: MenuCategories.Main,
		PermissionRequired: Permissions.ViewDDI,
	},
	{
		RouteLabel: 'Customer Management',
		RouteLink: '/customer-management',
		RouteIcon: <ManageAccountsOutlinedIcon />,
		RouteElement: CustomerManagement,
		IsActive: false,
		MenuCategory: MenuCategories.Main,
		PermissionRequired: Permissions.AddCustomer,
	},
	{
		RouteLabel: 'Customer Overview',
		RouteLink: '/customer-management/customer-overview',
		RouteIcon: <PersonSearchOutlinedIcon />,
		RouteElement: CustomerOverview,
		IsActive: false,
		MenuCategory: MenuCategories.Hidden,
		PermissionRequired: Permissions.ViewCustomer,
	},
	{
		RouteLabel: 'Lead Management',
		RouteLink: '/leads-management',
		RouteIcon: <SettingsApplicationsOutlinedIcon />,
		RouteElement: LeadManagement,
		IsActive: false,
		MenuCategory: MenuCategories.Main,
		PermissionRequired: Permissions.AddCustomer,
	},
	{
		RouteLabel: 'Price Books',
		RouteLink: '/pricebook-management',
		RouteIcon: <MenuBookOutlinedIcon />,
		RouteElement: PriceBookManagement,
		IsActive: false,
		MenuCategory: MenuCategories.Main,
		PermissionRequired: Permissions.ViewPriceBooks,
	},
	{
		RouteLabel: 'Address Management',
		RouteLink: '/address-management',
		RouteIcon: <Icon path={mdiMapMarkerRadius} size={1} />,
		RouteElement: AddressManagement,
		IsActive: false,
		MenuCategory: MenuCategories.Main,
		PermissionRequired:
			Permissions.ViewAddress &&
			Permissions.AddAddress &&
			Permissions.UpdateAddress,
	},
	{
		RouteLabel: 'Teams User Management',
		RouteLink: '/teams-user-management',
		RouteIcon: <GroupsIcon />,
		RouteElement: TeamsUserManagement,
		IsActive: false,
		MenuCategory: MenuCategories.Main,
		PermissionRequired: Permissions.ManageTeamsUsers,
	},
	// Other
	{
		RouteLabel: 'Knowledge Base',
		RouteLink: '/knowledge-base',
		RouteIcon: <HelpCenterOutlinedIcon />,
		RouteElement: <KnowledgeBase />,
		IsActive: false,
		MenuCategory: MenuCategories.Other,
		PermissionRequired: 0,
	},
	{
		RouteLabel: 'Compliance',
		RouteLink: '/compliance',
		RouteIcon: <AssuredWorkloadIcon />,
		RouteElement: Compliance,
		IsActive: false,
		MenuCategory: MenuCategories.Other,
		PermissionRequired: Permissions.ManageTeamsUsers,
	},
	{
		RouteLabel: 'Settings',
		RouteLink: '/settings',
		RouteIcon: <SettingsOutlinedIcon />,
		RouteElement: Settings,
		IsActive: false,
		MenuCategory: MenuCategories.Other,
		PermissionRequired: Permissions.ViewSettings,
	},
]

export const GraphScopes = ['.default']
export const TeamsScopes = [
	'48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation',
	'offline_access',
	'openid',
	'profile',
	'Domain.ReadWrite.All',
]
export const TeamsScopesForTeamsUserMGT = [
	'48ac35b8-9aa8-4d74-927d-1f4a14a0b239/.default',
]

// Teams User MGT - Link to retrieve users
export const TeamsUsersBaseLink =
	'https://graph.microsoft.com/v1.0/users?$select=userPrincipalName,id,mail,assignedLicenses,displayName&$count=true&$top=10'

export const MonthsInAYear = 12
export const OrderNotesMaximumCharactersAllowed = 4000
