import { useEffect, useRef, useState } from 'react'
import LoadingBox from '../../displays/LoadingBox/LoadingBox'
import { AddressMap } from '../../../../utils/interfaces/DBModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import {
	DDIStatuses,
	Roles,
	TNFilterKeyMappings,
	TrueFalseAny,
} from '../../../../utils/enums/enums'
import {
	BetaAPIMutation,
	BetaObject,
	DataResponse,
	DDIPaginationRequest,
	DDIPaginationResponse,
	DDIPaginationResponseResult,
	DDIRangesPaginationResponse,
	DDIRangesPaginationResponseResult,
	FilterRequest,
} from '../../../../utils/interfaces/APIModels'
import TNOverviewDisplay from './TNOverviewDisplay/TNOverviewDisplay'
import {
	DDIDisplay,
	DDIRangeDisplay,
	PaginationFilter,
} from '../../../../utils/interfaces/ComponentModels'
import EmptyDisplay from '../../displays/EmptyDisplay/EmptyDisplay'
import { usePostBetaObjectWithoutRefetchMutation } from '../../../../services/proxyAPIData'
import {
	toBeta,
	showErrorToast,
	toAlphaString,
	getEnumKeyByValue,
} from '../../../../utils/helperFunctions/helperFunctions'

const TNOverview = ({
	customerSpecificID,
}: {
	customerSpecificID?: string
}) => {
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [hasRows, setHasRows] = useState(false)

	// Global variables
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	const [postFilterDDIs] = usePostBetaObjectWithoutRefetchMutation()
	const [postFilterDDIRanges] = usePostBetaObjectWithoutRefetchMutation()

	//Constants
	var initialPageNumber = 1
	const noValueSelectedNumericValue = -999
	const noValueSelectedStringValue = '---'
	const pageSize = 10
	const [maxPageNo, setMaxPageNo] = useState(0)
	const [totalRecords, setTotalRecords] = useState(0)
	const [customerID, setCustomerID] = useState('')
	const [ddiRangesMaxPageNo, setDDIRangesMaxPageNo] = useState(0)
	const [ddiRangesTotalRecords, setDDIRangesTotalRecords] = useState(0)
	const [ddiValue, setDDIValue] = useState('')
	const [addressLine1Value, setAddressLine1Value] = useState('')
	const [ingressService, setIngressService] = useState(
		noValueSelectedStringValue
	)
	const [egressService, setEgressService] = useState(noValueSelectedStringValue)
	const [ddiStatusID, setDDIStatusID] = useState(noValueSelectedNumericValue)
	const [tnFilters, setTnFilters] = useState([] as PaginationFilter[])

	// Arrays
	const [addressMapList, setAddressMapList] = useState([] as AddressMap[])
	const tnDisplay = useRef([] as DDIDisplay[])
	const tnRangeDisplay = useRef([] as DDIRangeDisplay[])

	// Flags
	const [filterLoading, setFilterLoading] = useState(false)
	const [isDDIActive, setIsDDIActive] = useState(Number(TrueFalseAny.Any))

	useEffect(() => {
		if (customerSpecificID && customerSpecificID !== customerID) {
			resetFlagsAndArrays()
			setCustomerID(customerSpecificID)
		} else {
			// Make call to get paginated data
			fetchInitialPageData()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerSpecificID, customerID])

	// Reset flags and array - Mainly for when the customerID changes
	const resetFlagsAndArrays = () => {
		// Flags
		setAPICallsDone(false)

		// Arrays
		setAddressMapList([])
		tnDisplay.current = []
		tnRangeDisplay.current = []
	}

	//Fetch Data on initial load
	const fetchInitialPageData = () => {
		if (!apiCallsDone) {
			getInitialDDIData()
			getInitialDDIRangeData(initialPageNumber, {} as Record<string, string>)
		}
	}

	// GET: Retrieve Data for Filters and initial DDI list
	const getInitialDDIData = async (): Promise<void> => {
		handleResetFilterFields()
		setTnFilters([] as PaginationFilter[])
		var _filters = getFilters({} as Record<string, string>)

		//Get initial Data
		var initialDDIPaginationReq: DDIPaginationRequest = {
			pageNo: 1,
			pageSize: pageSize,
			filters: _filters,
		}

		var betaDataObj = await toBeta(initialDDIPaginationReq)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIs',
		}

		await postFilterDDIs(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiPaginationResponse =
							dataResponse?.Obj as DDIPaginationResponse
						setAddressMapList(
							ddiPaginationResponse.CustomerAddressMapList
								.AddressMapList as AddressMap[]
						)

						if (
							ddiPaginationResponse?.PaginatedResults &&
							ddiPaginationResponse.PaginatedResults.length > 0
						) {
							var ddiPaginationResponseResults =
								ddiPaginationResponse?.PaginatedResults as DDIPaginationResponseResult[]
							var _ddiDisplayList = [] as DDIDisplay[]

							ddiPaginationResponseResults.forEach((x) => {
								var _address = getAddressStringForPaginatedDDI(x)
								var _ddiDisplayListToAdd: DDIDisplay = {
									ID: Number(x.ID),
									DDI: x?.DDIValue + '',
									DDIRangeID: x.DDIRangeID,
									AddressID: x.AddressID,
									AddressFriendlyName: _address,
									IngressServiceID: x.ServiceInID,
									EgressServiceID: x.ServiceOutID,
									DDIStatus: DDIStatuses[Number(x.DDIStatusID)],
									CustomerName: x.CustomerName ?? 'No_Customer',
									CustomerID: x.CustomerID,
									CountryID: x.CountryID,
									FOCDate: x.FOCDate,
								}

								_ddiDisplayList.push(_ddiDisplayListToAdd)

								setMaxPageNo(
									Math.ceil(
										Number(ddiPaginationResponse?.TotalRecordsFound) / pageSize
									)
								)
								setTotalRecords(
									Number(ddiPaginationResponse?.TotalRecordsFound)
								)
							})
							tnDisplay.current = _ddiDisplayList
						}
						if (ddiPaginationResponse?.TotalRecordsFound > 0) {
							setHasRows(true)
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get TNs')
			})

		setAPICallsDone(true)
	}

	const getFilteredDDIsData = async (
		filterRequest: FilterRequest
	): Promise<void> => {
		if (filterRequest && filterRequest.pageNo > 0 && filterRequest.filters) {
			var betaDataObj = await toBeta(filterRequest)

			var betaObj: BetaObject = {
				Content: betaDataObj,
			}

			var betaApiMutation: BetaAPIMutation = {
				BetaObject: betaObj,
				QueryParam: 'FilterDDIs',
			}

			var ddiRangeFiltersSet = false

			const ddiRangeFilters = new Set([
				getEnumKeyByValue(TNFilterKeyMappings, 'TN Start'),
				getEnumKeyByValue(TNFilterKeyMappings, 'TN End'),
			])
			const _filters = filterRequest.filters
			ddiRangeFiltersSet = [...ddiRangeFilters].some((key) => key in _filters)

			if (ddiRangeFiltersSet) {
				//Filter DDI Ranges
				var ddiRangesBetaApiMutation: BetaAPIMutation = {
					BetaObject: betaObj,
					QueryParam: 'FilterDDIRanges',
				}

				await postFilterDDIRanges(ddiRangesBetaApiMutation)
					.unwrap()
					.then(async (response) => {
						if (response?.Content) {
							var dataResponse = JSON.parse(
								await toAlphaString(response?.Content)
							) as DataResponse

							if (dataResponse?.Obj) {
								var ddiRangesPaginationResponse =
									dataResponse?.Obj as DDIRangesPaginationResponse
								setAddressMapList(
									ddiRangesPaginationResponse.CustomerAddressMapList
										.AddressMapList as AddressMap[]
								)

								if (
									ddiRangesPaginationResponse?.PaginatedResults &&
									ddiRangesPaginationResponse.PaginatedResults.length >= 0
								) {
									var ddiRangesPaginationResponseResults =
										ddiRangesPaginationResponse?.PaginatedResults as DDIRangesPaginationResponseResult[]
									var _ddiRangesDisplayList = [] as DDIRangeDisplay[]

									ddiRangesPaginationResponseResults.forEach((x) => {
										var _ddiRangeDisplayToAdd: DDIRangeDisplay = {
											DDIRangeID: x.DDIRangeID,
											DDIRangeStart: x.RangeStart + '',
											DDIRangeEnd: x.RangeEnd + '',
											CustomerName: x.CustomerName,
											CustomerID: x.CustomerID + '',
										}
										_ddiRangesDisplayList.push(_ddiRangeDisplayToAdd)
									})
									setDDIRangesMaxPageNo(
										Math.ceil(
											Number(ddiRangesPaginationResponse?.TotalRecordsFound) /
												pageSize
										)
									)
									setDDIRangesTotalRecords(
										Number(ddiRangesPaginationResponse?.TotalRecordsFound)
									)
									tnRangeDisplay.current = _ddiRangesDisplayList
								}
							}
						}
					})
					.catch(() => {
						showErrorToast('Unable to get TN Ranges')
					})
			} else {
				//Filter DDIs
				await postFilterDDIs(betaApiMutation)
					.unwrap()
					.then(async (response) => {
						if (response?.Content) {
							var dataResponse = JSON.parse(
								await toAlphaString(response?.Content)
							) as DataResponse

							if (dataResponse?.Obj) {
								var ddiPaginationResponse =
									dataResponse?.Obj as DDIPaginationResponse
								if (
									ddiPaginationResponse?.PaginatedResults &&
									ddiPaginationResponse.PaginatedResults.length > 0
								) {
									var ddiPaginationResponseResults =
										ddiPaginationResponse?.PaginatedResults as DDIPaginationResponseResult[]
									var _ddiDisplayList = [] as DDIDisplay[]

									setAddressMapList(
										ddiPaginationResponse.CustomerAddressMapList
											.AddressMapList as AddressMap[]
									)

									ddiPaginationResponseResults.forEach((x) => {
										var _address = getAddressStringForPaginatedDDI(x)

										var _ddiDisplayListToAdd: DDIDisplay = {
											ID: Number(x.ID),
											DDI: x?.DDIValue + '',
											DDIRangeID: x.DDIRangeID,
											AddressFriendlyName: _address,
											AddressID: x.AddressID,
											IngressServiceID: x.ServiceInID,
											EgressServiceID: x.ServiceOutID,
											DDIStatus: DDIStatuses[Number(x.DDIStatusID)],
											CustomerName: x.CustomerName ?? 'No_Customer',
											CustomerID: x.CustomerID,
											CountryID: x.CountryID,
											FOCDate: x.FOCDate,
										}

										_ddiDisplayList.push(_ddiDisplayListToAdd)

										tnDisplay.current = _ddiDisplayList

										setMaxPageNo(
											Math.ceil(
												Number(ddiPaginationResponse?.TotalRecordsFound) /
													pageSize
											)
										)
										setTotalRecords(
											Number(ddiPaginationResponse?.TotalRecordsFound)
										)
									})
								}
							}
						}
					})
					.catch(() => {
						showErrorToast('Unable to get TNs')
					})
			}
		}

		setAPICallsDone(true)
	}

	const getAddressStringForPaginatedDDI = (
		ddiPaginatedResponseResult: DDIPaginationResponseResult
	) => {
		var _address = ''

		//if address is default
		if (
			ddiPaginatedResponseResult?.AddressLine1?.trim().toLowerCase() + '' ===
				'no_address' &&
			ddiPaginatedResponseResult?.City?.trim().toLowerCase() + '' === 'no_city'
		) {
			_address += 'Awaiting Address'
		} else {
			if ((ddiPaginatedResponseResult?.HouseNumber + '').length > 0) {
				_address += `${ddiPaginatedResponseResult.HouseNumber} `
			}

			_address += `${ddiPaginatedResponseResult.AddressLine1}, `

			if ((ddiPaginatedResponseResult?.AddressLine2 + '').length > 0) {
				_address += `${ddiPaginatedResponseResult.AddressLine2}, `
			}

			if ((ddiPaginatedResponseResult?.City + '').length > 0) {
				_address += `${ddiPaginatedResponseResult.City}, `
			}

			_address += `${ddiPaginatedResponseResult.StateName}, ${ddiPaginatedResponseResult.CountryName}`
		}

		return _address
	}

	const handleResetFilterFields = () => {
		setDDIValue('')
		setIngressService(noValueSelectedStringValue)
		setEgressService(noValueSelectedStringValue)
		setDDIStatusID(noValueSelectedNumericValue)
		setIsDDIActive(Number(TrueFalseAny.Any))
		setAddressLine1Value('')
	}

	const handleFilterData = async (
		pageNumber: number,
		filters: Record<string, string>
	): Promise<void> => {
		setFilterLoading(true)

		var _filters = getFilters(filters)

		//Get initial Data
		var ddiPaginationReq: DDIPaginationRequest = {
			pageNo: pageNumber,
			pageSize: pageSize,
			filters: _filters,
		}

		var betaDataObj = await toBeta(ddiPaginationReq)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIs',
		}

		await postFilterDDIs(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiPaginationResponse =
							dataResponse?.Obj as DDIPaginationResponse
						if (
							ddiPaginationResponse?.PaginatedResults &&
							ddiPaginationResponse.PaginatedResults.length >= 0
						) {
							var ddiPaginationResponseResults =
								ddiPaginationResponse?.PaginatedResults as DDIPaginationResponseResult[]
							setAddressMapList(
								ddiPaginationResponse.CustomerAddressMapList
									.AddressMapList as AddressMap[]
							)

							var _ddiDisplayList = [] as DDIDisplay[]

							ddiPaginationResponseResults.forEach((x) => {
								var _address = getAddressStringForPaginatedDDI(x)

								var _ddiDisplayListToAdd: DDIDisplay = {
									ID: Number(x.ID),
									DDI: x?.DDIValue + '',
									DDIRangeID: x.DDIRangeID,
									AddressID: x.AddressID,
									AddressFriendlyName: _address,
									IngressServiceID: x.ServiceInID,
									EgressServiceID: x.ServiceOutID,
									DDIStatus: DDIStatuses[Number(x.DDIStatusID)],
									CustomerName: x.CustomerName ?? 'No_Customer',
									CustomerID: x.CustomerID,
									CountryID: x.CountryID,
									FOCDate: x.FOCDate,
								}

								_ddiDisplayList.push(_ddiDisplayListToAdd)
							})

							tnDisplay.current = _ddiDisplayList

							setMaxPageNo(
								Math.ceil(
									Number(ddiPaginationResponse?.TotalRecordsFound) / pageSize
								)
							)
							setTotalRecords(Number(ddiPaginationResponse?.TotalRecordsFound))
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get TNs')
			})

		var _filterRecords = {} as Record<string, string>
		tnFilters.forEach((tnFilter) => {
			_filterRecords[tnFilter.Key] = tnFilter.Value
		})

		setFilterLoading(false)
	}

	//DDI Filters
	const getFilters = (filters: Record<string, string>) => {
		//check which filters to use
		if (customerSpecificID && customerID && customerID.length > 0) {
			filters['CustomerPartnerID'] = `${partnerID}`
			filters['CustomerID'] = `${customerID}`
		} else if (!customerSpecificID) {
			switch (roleID) {
				case Roles.CustomerAdmin:
					filters['CustomerID'] = `${loggedInUser.customerID}`
					break
				case Roles.PartnerAdmin:
					filters['CustomerPartnerID'] = `${partnerID}`
					break
				default:
					break
			}
		}

		if (
			filters['TN'] !== noValueSelectedStringValue &&
			(filters['TN'] + '').length > 0
		) {
			filters['DDI'] = filters['TN']
			delete filters['TN']
		}

		if (
			filters['Ingress ServiceID'] !== noValueSelectedStringValue &&
			(filters['Ingress ServiceID'] + '').length > 0
		) {
			filters['ServiceInID'] = filters['Ingress ServiceID']
			delete filters['Ingress ServiceID']
		}

		if (
			filters['Egress ServiceID'] !== noValueSelectedStringValue &&
			(filters['Egress ServiceID'] + '').length > 0
		) {
			filters['ServiceOutID'] = filters['Egress ServiceID']
			delete filters['Egress ServiceID']
		}

		if (
			filters['TN Status'] !== noValueSelectedStringValue &&
			(filters['TN Status'] + '').length > 0
		) {
			filters['DDIStatusID'] = filters['TN Status']
			delete filters['TN Status']
		}

		return filters
	}

	//Get Initial DDI Ranges
	const getInitialDDIRangeData = async (
		pageNumber: number,
		filters: Record<string, string>
	): Promise<void> => {
		setFilterLoading(true)
		tnRangeDisplay.current = []

		setTnFilters([] as PaginationFilter[])

		var _filters = getDDIRangesFilters(filters)

		//Get initial Data
		var ddiPaginationReq: DDIPaginationRequest = {
			pageNo: pageNumber,
			pageSize: pageSize,
			filters: _filters,
		}

		var betaDataObj = await toBeta(ddiPaginationReq)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIRanges',
		}

		await postFilterDDIRanges(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiRangesPaginationResponse =
							dataResponse?.Obj as DDIRangesPaginationResponse
						if (
							ddiRangesPaginationResponse?.PaginatedResults &&
							ddiRangesPaginationResponse.PaginatedResults.length >= 0
						) {
							var ddiRangesPaginationResponseResults =
								ddiRangesPaginationResponse?.PaginatedResults as DDIRangesPaginationResponseResult[]
							setAddressMapList(
								ddiRangesPaginationResponse.CustomerAddressMapList
									.AddressMapList as AddressMap[]
							)

							var _ddiRangesDisplayList = [] as DDIRangeDisplay[]

							ddiRangesPaginationResponseResults.forEach((x) => {
								var _ddiRangeDisplayToAdd: DDIRangeDisplay = {
									DDIRangeID: x.DDIRangeID,
									DDIRangeStart: x.RangeStart + '',
									DDIRangeEnd: x.RangeEnd + '',
									CustomerName: x.CustomerName,
									CustomerID: x.CustomerID + '',
								}
								_ddiRangesDisplayList.push(_ddiRangeDisplayToAdd)
							})
							setDDIRangesMaxPageNo(
								Math.ceil(
									Number(ddiRangesPaginationResponse?.TotalRecordsFound) /
										pageSize
								)
							)
							setDDIRangesTotalRecords(
								Number(ddiRangesPaginationResponse?.TotalRecordsFound)
							)
							tnRangeDisplay.current = _ddiRangesDisplayList
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get TN Ranges')
			})

		setFilterLoading(false)
	}

	//DDI Range Filters
	const getDDIRangesFilters = (filters: Record<string, string>) => {
		//check which filters to use
		if (customerSpecificID && customerID && customerID.length > 0) {
			filters['CustomerPartnerID'] = `${partnerID}`
			filters['CustomerID'] = `${customerID}`
		} else if (!customerSpecificID) {
			switch (roleID) {
				case Roles.CustomerAdmin:
					filters['CustomerID'] = `${loggedInUser.customerID}`
					break
				case Roles.PartnerAdmin:
					filters['CustomerPartnerID'] = `${partnerID}`
					break
				default:
					break
			}
		}

		return filters
	}

	const updateTNFilters = (filters: PaginationFilter[]) => {
		setTnFilters(filters)
	}

	return apiCallsDone ? (
		hasRows ? (
			// Display datagrid
			<TNOverviewDisplay
				ddiDisplay={tnDisplay.current}
				ddiRangeDisplay={tnRangeDisplay.current}
				addressMapList={addressMapList}
				handleFilterData={handleFilterData}
				onUpdateTNFilters={updateTNFilters}
				tnFilters={tnFilters}
				getFilteredDDIsData={getFilteredDDIsData}
				maxPageNo={maxPageNo}
				totalRecords={totalRecords}
				filterLoading={filterLoading}
				getInitialDDIRangeData={getInitialDDIRangeData}
				ddiRangesMaxPageNo={ddiRangesMaxPageNo}
				ddiRangesTotalRecords={ddiRangesTotalRecords}
				getInitialDDIData={getInitialDDIData}
			/>
		) : (
			<EmptyDisplay
				title='No TNs found'
				description='There were no TNs found'
			/>
		)
	) : (
		// Show loading
		<LoadingBox title='Getting TNs' />
	)
}

export default TNOverview
