import { Alert, Box, MenuItem, Typography, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { ModalForm } from '../../../inputs/ModalForm/ModalForm'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import { StyledSelect } from '../../../../../styles/styledComponents/inputs/StyledSelect'
import { AddressMap } from '../../../../../utils/interfaces/DBModels'
import {
	DDIDisplay,
	DDIRangeDisplay,
} from '../../../../../utils/interfaces/ComponentModels'
import { showSuccessToast } from '../../../../../utils/helperFunctions/helperFunctions'
import { StyledTextField } from '../../../../../styles/styledComponents/inputs/StyledTextField'
import { useNavigate } from 'react-router'

const DDIAddressUpdate = ({
	isRange,
	ddiDisplay,
	ddiRangeDisplay,
	addressMapList,
	handleAddressUpdate,
	onClose,
}: {
	isRange: boolean
	ddiDisplay?: DDIDisplay
	ddiRangeDisplay?: DDIRangeDisplay
	addressMapList: AddressMap[]
	handleAddressUpdate: (
		addressMapID: number,
		ddiID?: number,
		ddiRangeID?: number
	) => Promise<void>
	onClose: () => void
}) => {
	// Display constant
	const [currentAdressMapID, setCurrentAddressMapID] = useState(0)
	const [modalTitle, setModalTitle] = useState('')
	const [modalDesc, setModalDesc] = useState('')
	const [modalButtonText, setModalButtonText] = useState('')

	// Flags
	const [btnLoading, setBtnLoading] = useState(false)
	const [btnDisabled, setBtnDisabled] = useState(true)

	// General
	const navigate = useNavigate()

	// Use Effect: Set form constants
	useEffect(() => {
		setFormConstants()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addressMapList, ddiRangeDisplay, ddiDisplay])

	// Set all form constants based on fields
	const setFormConstants = () => {
		// Check if there are any address mapped
		if (addressMapList.length > 0) {
			// Check range or else single TN
			if (isRange) {
				setModalTitle('Allocate Location to TN Range')
				setModalDesc('Allocate Location for TN Range')
				setModalButtonText('Location Allocation')
			} else {
				setModalTitle('Update TN Location')
				setModalDesc('Update Location for TN')
				setModalButtonText('Update Location')
			}

			// Handle disable button logic
			if (addressMapList && ddiDisplay) {
				var currentAddressMap = addressMapList.find(
					(x) => x.AddressMapID === Number(ddiDisplay.AddressMapID)
				)
				if (currentAddressMap) {
					setCurrentAddressMapID(Number(currentAddressMap.AddressMapID))
					setBtnDisabled(true)
				} else {
					setBtnDisabled(true)
				}
			}
		} else {
			setModalTitle('Cannot Update TN Location')
			setModalDesc(
				'Please note this TN contains no related addresses to be assigned to. Please add one under Address Management in the same location as the dialing code'
			)
			setModalButtonText('Proceed to Address Management')
			setBtnDisabled(false)
		}
	}

	// Handle functions
	const handleAddressMapIDChange = (e: { target: any }) => {
		var newValue = e.target.value
		if (newValue) {
			var newAddressMap = addressMapList.find(
				(x) => x.AddressMapID === Number(newValue)
			)
			if (newAddressMap) {
				setCurrentAddressMapID(Number(newAddressMap.AddressMapID))
				setBtnDisabled(false)
			} else {
				setBtnDisabled(true)
			}
		} else {
			setBtnDisabled(true)
		}
	}

	const handleButtonClick = async () => {
		setBtnLoading(true)
		if (ddiDisplay && !isRange) {
			if (Number(ddiDisplay.AddressMapID) === Number(currentAdressMapID)) {
				// Adress selected is the same as address allocated to TN or Range
				showSuccessToast('Successfully updated TN location')
			} else {
				handleAddressUpdate(Number(currentAdressMapID), Number(ddiDisplay.ID))
			}
		} else if (ddiRangeDisplay && isRange) {
			handleAddressUpdate(
				Number(currentAdressMapID),
				Number(ddiRangeDisplay.DDIRangeID)
			)
		}
		setBtnLoading(false)
		handleCloseAddressUpdateModal()
	}

	const handleCloseAddressUpdateModal = () => {
		onClose()
	}

	//info styling
	const InfoAlert = styled(Alert)({
		'& .MuiAlert-icon': {
			color: 'black',
		},
		backgroundColor: '#fff',
		color: 'black',
		fontSize: '14px',
	})

	// Handle navigate
	const handleNavigateToAddressMGT = () => {
		navigate('/address-management')
	}

	return (
		<ModalForm
			icon={<SettingsApplicationsOutlinedIcon />}
			title={modalTitle}
			buttonText={modalButtonText}
			description={modalDesc}
			buttonAction={
				addressMapList.length > 0
					? handleButtonClick
					: handleNavigateToAddressMGT
			}
			buttonLoading={btnLoading}
			closeModal={handleCloseAddressUpdateModal}
			buttonDisabled={btnDisabled}>
			{addressMapList.length > 0 && (
				<>
					{!isRange && (
						/* Form Group - TN */
						<Box className='form-group'>
							<Typography component='p' className='form-label'>
								TN
							</Typography>
							<StyledTextField
								disabled
								fullWidth
								value={ddiDisplay?.DDI + ''}
								type='text'
								placeholder='TN'
								style={{ fontSize: '1rem' }}
							/>
						</Box>
					)}

					{isRange && (
						<>
							{/* Form Group - TN Start*/}
							<Box className='form-group'>
								<Typography component='p' className='form-label'>
									TN Start
								</Typography>
								<StyledTextField
									disabled
									fullWidth
									value={ddiRangeDisplay?.DDIRangeStart + ''}
									type='text'
									placeholder='TN Start'
									style={{ fontSize: '1rem' }}
								/>
							</Box>
							<Box className='form-group'>
								<Typography component='p' className='form-label'>
									TN End
								</Typography>
								<StyledTextField
									disabled
									fullWidth
									value={ddiRangeDisplay?.DDIRangeEnd + ''}
									type='text'
									placeholder='TN End'
									style={{ fontSize: '1rem' }}
								/>
							</Box>
						</>
					)}

					{/* Form Group - Address*/}
					<Box className='form-group'>
						<Typography component='p' className='form-label'>
							Location
							<Typography component='span' className='required'>
								*
							</Typography>
							{isRange && (
								<InfoAlert severity='info' style={{ padding: '0' }}>
									Updating the Range location will update the location of all
									TNs in range.
								</InfoAlert>
							)}
						</Typography>
						<StyledSelect
							fullWidth
							type='text'
							value={Number(currentAdressMapID)}
							placeholder='Location'
							onChange={handleAddressMapIDChange}>
							<MenuItem disabled value={0}>
								Please select a location
							</MenuItem>
							{addressMapList.map((option, index) => (
								<MenuItem key={index} value={Number(option.AddressMapID)}>
									{option.AddressName + '' === 'null'
										? `${option.Address?.AddressLine1} ${
												option.Address?.AddressLine2 !== null
													? ', ' + option.Address?.AddressLine2
													: ''
										  }`
										: option.AddressName + ''}
								</MenuItem>
							))}
						</StyledSelect>
					</Box>
				</>
			)}
		</ModalForm>
	)
}

export default DDIAddressUpdate
